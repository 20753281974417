import React from 'react'
import NextLink from 'next/link'

export const ErrorPage = () => {
  return (
    <NextLink href={'/'} passHref>
      TOP
    </NextLink>
  )
}
